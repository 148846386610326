import { FC, useEffect, useState, useRef } from "react";
import Footer from "../common/Footer";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import Header from "../common/Header";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import groupImg from ".././assets/images/Group.png";
import axios from "axios";

export default function ForgotPassword() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [workEmail, setWorkEmail] = useState("");
  const [workEmailError, setWorkEmailError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onWorkemailChange = (e: any) => {
    const value = e.target.value;
    const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (value.length > 255) {
      return;
    }

    setWorkEmail(value);
    if (!value.trim()) {
      setWorkEmailError("Please enter work email");
    } else if (regex.test(value) === false) {
      setWorkEmailError("Please enter a valid work email");
    } else {
      setWorkEmailError("");
    }
  };
  const checkValidation = () => {
    let returnValue = true;
    setWorkEmailError("");
    if (workEmail == null || workEmail == "") {
      setWorkEmailError("Please enter work email");
      returnValue = false;
    } else {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (regex.test(workEmail) === false) {
        setWorkEmailError("Please enter a valid work email");
        returnValue = false;
      }
    }
    return returnValue;
  };
  const onForgotPasswordClicked = () => {
    if (checkValidation()) {
      setSubmitLoading(true);

      var config = {
        method: "get",
        url: `${process.env.REACT_APP_AUTHAPI}/api/account/forgetpassword?userEmail=${workEmail}`,
        headers: {},
      };

      axios(config)
        .then((response) => {
          setSubmitLoading(false);
          setShowSuccessMessage(true);
        })
        .catch((error) => {
          setSubmitLoading(false);
          setShowSuccessMessage(false);
          toast.current.show({ severity: "error", detail: "Unknow error while reset password!", life: 3000 });
        });
    }
  };
  const onLoginClick = () => {
    navigate("/");
  };
  
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      onForgotPasswordClicked();
    }
  };
  return (
    <div className="login-page">
      <Toast ref={toast} />

      <Header pagename="forgotpassword" />
      
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
        <div className="register-box position-relative">
          <div className="row register-header mx-auto p-3">
            <div className="col-12 ">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-12 d-flex  align-items-center">
                  <img src={groupImg} className="me-2" />
                  <span className="text-16">
                    <b>Forgot Password</b>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <>
            {showSuccessMessage ? (
              <div className="row mx-auto  success-box">
                <div className="col-12 agent-success-message">
                  <p>The password reset link has been sent to your email. Please check your email, and follow instructions to reset your password.</p>
                </div>
                <AtkButton label="Login" onClick={onLoginClick} className="pf-primary-btn w-auto" />
              </div>
            ) : (
              <div className="row mx-auto d-flex align-items-center px-3 py-4 ">
                <div className="col-12">
                  <h4 className="">Enter your email and we'll send you a link to reset your password.</h4>
                </div>
                <div className=" col-4 text-start mt-3">
                  <label>
                    Work Email<span className="text-danger "> *</span>{" "}
                  </label>
                </div>
                <div className={workEmailError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                  <AtkTextField
                    type="text"
                    id="txtWorkEmail"
                    name="workEmail"
                    onChange={onWorkemailChange}
                    value={workEmail}
                    className="input-field"
                    placeholder="Enter work email"
                    onKeyDown={onEnterClick}
                  />
                </div>
                <div className=" col-4 text-start "></div>
                <div className="col-8 ">
                  <span className="text-danger text-12 ">{workEmailError}</span>
                </div>

                <div className="col-12 my-3  forgot-password-btn">
                  {submitLoading ? (
                    <div className="p-1">
                      <AtkLoader />{" "}
                    </div>
                  ) : (
                    <AtkButton label="Send" onClick={onForgotPasswordClicked} className="pf-primary-btn w-auto" />
                  )}
                </div>
              </div>
            )}
          </>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
}
