
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column";
import { useEffect, useRef } from "react";
import CommonValues from "../../common/utils";
import { useNavigate } from "react-router-dom";
export default function DriverScheduleDetails(props: any) {
  const navigate= useNavigate();
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-12  history-table p-3 rounded-bottom">
          <DataTable
            value={props.driverScheduleResponse}
            paginator
            paginatorTemplate="PrevPageLink PageLinks NextPageLink"
            rows={8}
            selectionMode="single"
          >
            <Column header="License Number" field= {props.fromHistoryPage == true ? "LicenseNumber":"licenseNumber"}></Column>
            <Column header="DOB" field={props.fromHistoryPage == true ? "DOB":"dob"} ></Column>
            <Column header="Name" field={props.fromHistoryPage == true ? "Name":"name"} ></Column>
            <Column header="Excluded" field={props.fromHistoryPage == true ?"Excluded":"excluded"}></Column>
          </DataTable>
        </div>
      </div>
    </>
  )
}