import React from 'react'
import { Toast } from "primereact/toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Header from '../../common/Header';
import MTMScanMiddleHeader from './MTMScanMiddleHeader';
import MTMScanSidebar from './MTMScanSidebar';
import CommonValues from "../../common/utils";
import { useNavigate } from "react-router-dom";
import AtkLoader from '../../AtlaskitControls/AtkLoader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import feedbackImg from '../../assets/images/feedback.svg'
import { OverlayPanel } from 'primereact/overlaypanel';
import PreviewIcon from '../../assets/images/ViewDetailsIcon.png'
import axios from 'axios';
import moment from 'moment';
import AtkButton from '../../AtlaskitControls/AtkButton';
import { Dialog } from 'primereact/dialog';
import InsuredDetails from './InsuredDetails';
import CoverageLimitDetails from './CoverageLimitDetails';
import DriverScheduleDetails from './DriverScheduleDetails';
import ScheduledVehiclesDetails from './ScheduledVehiclesDetails';
export default function MTMScanHistory() {
  const toast: any = useRef()
  const navigate = useNavigate();
  const feedbackRef = useRef<OverlayPanel>(null);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [feedbackError, setFeedbackError] = useState("");
  const [feedbackData, setFeedBackData] = useState<any>([]);
  const [mtmScanHistory, setMTMScanHistory] = useState<any>([])
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [submissionId, setSubmissionId] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [previewFileLoading, setPreviewFileLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [insuredName, setInsuredName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [policyPremium, setPolicyPremium] = useState("");
  const [coveragelimitResponse, setCoverageLimitResponse] = useState<any>([]);
  const [scheduledVehicleResponse, setScheduledVehicleResponse] = useState<any>([]);
  const [driverScheduleResponse, setDriverScheduleResponse] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("Insured");
  const [selectDocumentType,setSelectDocumentType]=useState("");
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getMTMScanScanHistory()
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getMTMScanScanHistory = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/MTMScan/history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setMTMScanHistory(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting mtmscan history", life: 3000 });
          }
        }
        setHistoryLoading(false);
      });
  };


  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedbackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };

  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setMTMScanHistory((prevResponses: any) =>
      prevResponses.map((response: any) => (response.submissionId === rowData.submissionId ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setMTMScanHistory((prevResponses: any) =>
      prevResponses.map((response: any) => (response.submissionId === rowData.submissionId ? { ...response, isLiked: liked } : response))
    );
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      setSubmissionId(rowData.submissionId);
      onSaveFeedback(rowData);
    }
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/MTMScan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.submissionId,
      IsLiked: rowData.isLiked,
      Feedback: feedbackVal,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/MTMScan/isliked`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Ratings Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };
  const statusTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <b className="text-success"> Success</b>
    ) : rowData.status === "Failed" ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };

  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };

  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedbackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };

  const actionTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <>
        <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="show-cursor" />
      </>
    ) : (
      <>
        <span className=" text-12 quote-not-span">Not Available</span>
      </>
    );
  };

  const onPreviewClick = (rowData: any) => {
    let responseJson=JSON.parse(rowData.responseJson);
    setSubmissionId(rowData.submissionId);
    setSelectDocumentType(rowData.documentType)
    let driverScheduleResponse: any = [];
    let coveragelimitResponse: any = [];
    let scheduledVehicleResponse: any = [];
    driverScheduleResponse = responseJson.ResponseJson[0].DriverSchedule;
    coveragelimitResponse.push(responseJson.ResponseJson[0].AutoMobileLiability);
    scheduledVehicleResponse = responseJson.ResponseJson[0].Vehicles
    setInsuredName(responseJson.ResponseJson[0].NamedInsured)
    setAddress(responseJson.ResponseJson[0].StreetAddress)
    setCity(responseJson.ResponseJson[0].City)
    setState(responseJson.ResponseJson[0].State)
    setZipCode(responseJson.ResponseJson[0].ZipCode)
    setEffectiveDate(responseJson.ResponseJson[0].EffectiveDate)
    setExpirationDate(responseJson.ResponseJson[0].ExpirationDate)
    setPolicyNumber(responseJson.ResponseJson[0].PolicyNumber)
    setPolicyPremium(responseJson.ResponseJson[0].PolicyPremium.replace(/[,$]/g, "").replace(/\.00$/, "").trim())
    setDriverScheduleResponse(driverScheduleResponse)
    setCoverageLimitResponse(coveragelimitResponse);
    setScheduledVehicleResponse(scheduledVehicleResponse)
    setShowPopUp(true);
    const token = CommonValues.GetToken();
    var data = {
      TransactionId: rowData.submissionId,
      FileName: rowData.fileName,
      Target:rowData.documentType
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/MTMScan/previewdocument`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        setPdfUrl(response.data.fileUrl);
        setPreviewFileLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting  history details", life: 3000 });
          }
        }
        setPreviewFileLoading(false);
      });
    setPreviewFileLoading(false);
  };

  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setPdfUrl("");
  };

  const handleTabClick = (tabName: any) => {
    setActiveTab(tabName);
};
  return (
    <>
      <Toast ref={toast} />
      <div className="smart-scan-history-main-page">
        <Header pagename="smart-scan-history" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <MTMScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto" >
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <MTMScanSidebar pagename="mtm-scan-history" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  {historyLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <AtkLoader />{" "}
                    </div>
                  ) : (
                    <div className="col-12  history-table mt-3">
                      <DataTable
                        value={mtmScanHistory}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        selectionMode="single"
                      >
                        <Column header="File Name" field="fileName"></Column>
                        <Column
                          header="Submitted Date"
                          field="created"
                          body={(rowData) => {
                            const utcdate = moment.utc(rowData.created);
                            var localDate = moment(utcdate).local();
                            return <span className="">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                          }}
                          sortable
                        ></Column>
                        <Column header="UpdateInAMS" field='updateInNowcerts'></Column>
                        <Column header="Status" field="status" body={statusTemplate}></Column>
                        <Column header="Action" body={actionTemplate}></Column>
                        <Column header="Feedback" body={feedbackTemplate}></Column>
                      </DataTable>
                    </div>
                  )}

                  <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                    <div className="row mx-auto d-flex align-items-center mb-2">
                      <div className="col-12 feedback-header">
                        <h4>Feedback</h4>
                      </div>
                      <div className={"form-group col-12 mb-2 text-12"}>
                        <textarea
                          name="feedback"
                          onChange={(e) => onFeedbackChange(e.target.value)}
                          value={feedbackData.feedback}
                          className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                          rows={4}
                          placeholder="Enter your feedback here"
                        />
                        <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                      </div>
                      <div className="col-4 d-flex justify-content-evenly">
                        <i
                          className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                              ${feedbackData.isLiked == true ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbsUpClick(feedbackData)}
                        ></i>
                        <i
                          className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                ${feedbackData.isLiked == false ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbDownClick(feedbackData)}
                        ></i>
                      </div>
                      <div className="col-4 text-end p-2 pe-0  ">
                        <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                      </div>
                      <div className="col-4 text-end p-2  ">
                        {feedbackLoading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <AtkLoader />
                          </div>
                        ) : (
                          <AtkButton
                            label="Submit"
                            onClick={() => onSubmitFeedbackClick(feedbackData)}
                            className="pf-secondary-btn w-auto "
                          />
                        )}
                      </div>
                    </div>
                  </OverlayPanel>

                  <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
                    {previewFileLoading ? (
                      <div className="d-flex align-items-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                      <div className="row">
                      <div className="col-6 border-end">
                        <b>Uploaded PDF</b>
                        <iframe src={pdfUrl} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
                      </div>
                      <div className='col-6'>
                      <div className="row p-0 d-flex align-items-center mx-auto smartscan-tab">
                                    <div className="col-12 d-flex align-items-center justify-content-start">
                                        <span
                                            className={activeTab === "Insured" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                            onClick={() => {
                                                handleTabClick("Insured");
                                            }}
                                        >

                                            Insured Details
                                        </span>
                                        <span
                                            className={activeTab === "CoverageLimits" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                            onClick={() => {
                                                handleTabClick("CoverageLimits");
                                            }}
                                        >
                                            Coverage Limits
                                        </span>
                                        <span
                                            className={activeTab === "DriverSchedule" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                            onClick={() => {
                                                handleTabClick("DriverSchedule");
                                            }}
                                        >
                                            Driver Schedule
                                        </span>
                                        <span
                                            className={activeTab === "ScheduleVehicles" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                            onClick={() => {
                                                handleTabClick("ScheduleVehicles");
                                            }}
                                        >
                                            Schedule Vehicles
                                        </span>
                                    </div>
                                </div>
                                {activeTab === "Insured" ? (
                                    <InsuredDetails
                                        insuredName={insuredName}
                                        address={address}
                                        city={city}
                                        state={state}
                                        zipCode={zipCode}
                                        policyNumber={policyNumber}
                                        policyPremium={policyPremium}
                                        effectiveDate={effectiveDate}
                                        expirationDate={expirationDate}
                                        fromHistoryPage={true}
                                    />

                                ) : null}
                                {activeTab === "CoverageLimits" ? (
                                    <CoverageLimitDetails
                                        coveragelimitResponse={coveragelimitResponse}
                                        fromHistoryPage={true}
                                        selectDocumentType={selectDocumentType}
                                    />

                                ) : null}
                                {activeTab === "DriverSchedule" ? (
                                    <DriverScheduleDetails
                                        driverScheduleResponse={driverScheduleResponse}
                                        fromHistoryPage={true}
                                    />

                                ) : null}
                                {activeTab === "ScheduleVehicles" ? (
                                    <ScheduledVehiclesDetails
                                        scheduledVehicleResponse={scheduledVehicleResponse}
                                        fromHistoryPage={true}
                                    />
                                ) : null}
                      </div>
                      </div>
                    )}
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
} 