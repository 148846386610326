import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import COIHistory from "../src/RTG/NowCertsOcrScan/COIHistory";
import GodMode from "./Admin/GodMode";
import "./App.css";
import CommonSetting from "./CommonSettings/CommonSetting";
import FenrisMainPage from "../src/Fenris-poc/Fenris/FenrisMainPage";
import MomentumTokenValidation from "./MomentumBridge/MomentumTokenValidation";
import ErrorPage from "./OtherPages/ErrorPage";
import NowcertsSsoPage from ".././src/MomentumBridge/NowcertsSsoPage";
import TermsAndConditions from "./OtherPages/TermsAndConditions";
import LandingPage from "./popup-page/LandingPage";
import LinkUser from "./popup-page/LinkUser";
import Login from "../src/Account/Login";
import StartupPage from "./popup-page/StartupPage";
import NowCertsCOIScan from "./RTG/NowCertsOcrScan/NowCertsCOIScan";
// import SmartScan from "./RTG/NowCertsOcrScan/NowCertsSmartScan";
import SmartScan from "./RTG/AcordSmartScan/SmartScanMainpage";

import PFHistory from "./RTG/PremiumFinance/PFHistory";
import PFSavedQuotes from "./RTG/PremiumFinance/PFSavedQuotes";
import PremiumFinance from "./RTG/PremiumFinance/PremiumFinance";
import PfSettings from "./RTG/PremiumFinance/Setting/PfSettings";
import PrimaryAmsSetting from "./SetupWizard/PrimaryAmsSetting";
import SetupStart from "./SetupWizard/SetupStart";
import Details from "./UserProfile/Details";
import Billing from "./Billing/Billing";
import Vehicle from "./VehicleData/Vehicle";
import Register from "./Account/Register";
import AgentRegistration from "./Account/AgentRegistration";
import Setpassword from "./Account/Setpassword";
import ResetPassword from "./Account/ResetPassword";
import ForgotPassword from "./Account/ForgotPassword";
import RegistrationThroughGigcode from "./Account/RegistrationThroughGigcode";
import PFScanMainPage from "../src/RTG/PremiumFinanceOCRScan/PFScanMainPage";
import RequestDemo from "./Request-Service/RequestDemo";
import MySubscription from "./Billing/MySubscription";
import PaymentHistory from "./Billing/PaymentHistory";
import BuyCredis from "./Billing/BuyCredis";
import PaymentMethod from "./Billing/PaymentMethod";
import QuickQuoteConfirmation from "./RTG/PremiumFinance/QuickQuoteConfirmation";
import NaicsLandingPage from "./NaicsClassificationAI/NaicsLandingPage";
import NaicsHistory from "./NaicsClassificationAI/NaicsHistory";
import SmartScanHistory from "./RTG/AcordSmartScan/SmartScanHistory";
import CommissionScanHistory from "./RTG/CommissionScan/CommissionScanHistory";
import CommissionScan from "./RTG/CommissionScan/CommissionScan";
import VehicelHistory from "./VehicleData/VehicleHistory";
import PFScanHistory from "./RTG/PremiumFinanceOCRScan/PFScanHistory";
import FenrisHistory from "./Fenris-poc/Fenris/FenrisHistory";
import FenrisLandingPage from "./Fenris-poc/Fenris/FenrisLandingPage";
import SearchNewInsuredPage from "./Fenris-poc/Fenris/SearchNewInsuredPage";
import ValchoiceLandingPage from "./RTG/ValChoice/ValchoiceLandingPage";
import ValchoiceHistory from "./RTG/ValChoice/ValchoiceHistory";
import PolicyComparison from "./PolicyComparison/PolicyComparison";
import RabbitSign from "./RabbitSign/RabbitSign";
import RabbitSignHistory from "./RabbitSign/RabbitSignHistory";
import MTMScanLadingPage from "./RTG/MTMScan/MTMScanLadingPage";
import MTMScanHistory from "./RTG/MTMScan/MTMScanHistory";
import PolicyComparisonHistory from "./PolicyComparison/PolicyComparisonHistory";
import AppetiteLandingPage from "./RateRiskAppetite/AppetiteLandingPage";
import AppetiteHistory from "./RateRiskAppetite/AppetiteHistory";
import HomeOwnersMainPage from "./RTG/HomeOwners/HomeOwnersMainPage";
import HomeOwnersHistory from "./RTG/HomeOwners/HomeOwnersHistory";

const hostname = window.location.hostname;

function App() {
  const [cssLoaded, setCssLoaded] = useState(false);
  useEffect(() => {
    const loadCss = async () => {
      try {
        if (hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2) {
          await import("./assets/css/momentumtoolbox-color.css");
        } else {
          await import("./assets/css/app-color.css");
        }
        setCssLoaded(true);
      } catch (error) {
        console.error("Error loading CSS:", error);
      }
    };

    loadCss();
  }, [hostname]);

  if (!cssLoaded) {
    return (
      <div>
        <Skeleton width="100%" height="100%" className="app-skeleton"></Skeleton>
      </div>
    );
  }

  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/agencyonboarding/:index?" element={<Register />}></Route>
          <Route path="/agencyonboarding/gigcode" element={<RegistrationThroughGigcode />}  />
          <Route path="/agentonboarding/:index" element={<AgentRegistration />}></Route>
          <Route path="/setpassword/:index" element={<Setpassword />}></Route>
          <Route path="/resetpassword/:index" element={<ResetPassword />}></Route>
          <Route path="/forgotPassword" element={<ForgotPassword />}></Route>
          <Route path="/landingpage" element={<LandingPage />}></Route>
          <Route path="/pfhistory" element={<PFHistory />}></Route>
          <Route path="/pfsavedquotes" element={<PFSavedQuotes />}></Route>
          <Route path="/startuppage" element={<StartupPage />}></Route>
          <Route path="/pfhisotry" element={<PFHistory />}></Route>
          <Route path="/premiumfinance" element={<PremiumFinance />}></Route>
          <Route path="/pfsettings" element={<PfSettings />}></Route>
          <Route path="/commonsettings/:param" element={<CommonSetting />}></Route>
          <Route path="/commonsettings" element={<CommonSetting />} />
          <Route path="/nowcertssso" element={<NowcertsSsoPage />}></Route>
          <Route path="/terms" element={<TermsAndConditions />}></Route>
          <Route path="/userprofile" element={<Details />}></Route>
          <Route path="/setupwizard" element={<SetupStart />}></Route>
          <Route path="/setupwizardstart" element={<PrimaryAmsSetting />}></Route>
          <Route path="/linkuser" element={<LinkUser />}></Route>
          <Route path="/bridge" element={<MomentumTokenValidation />} />
          <Route path="/nowcertcoiscan" element={<NowCertsCOIScan />} />
          <Route path="/nowcertcoiscanhistory" element={<COIHistory />} />
          <Route path="/fenrisprefill" element={<FenrisMainPage />} />
          <Route path="/fenrisprefillhistory" element={<FenrisHistory />} />
          <Route path="/smartscan" element={<SmartScan />} />
          <Route path="/smartscanhistory" element={<SmartScanHistory />} />
          <Route path="/admin/users" element={<GodMode />} />
          <Route path="/billing" element={<Billing />}></Route>
          <Route path="/vehicleprefill" element={<Vehicle />}></Route>
          <Route path="/vehicleprefillhistory" element={<VehicelHistory />}></Route>
          <Route path="/pfscan" element={<PFScanMainPage/>}></Route>
          <Route path="/pfscanhistory" element={<PFScanHistory/>}></Route>
          <Route path="/requestdemo" element={<RequestDemo/>}></Route>
          <Route path="/commissionscan" element={<CommissionScan/>}></Route>
          <Route path="/commissionscanhistory" element={<CommissionScanHistory/>}></Route>
          <Route path="/mysubscription" element={<MySubscription/>}></Route>
          <Route path="/paymenthistory" element={<PaymentHistory/>}></Route>
          <Route path="/buycredits" element={<BuyCredis/>}></Route>
          <Route path="/paymentmethod" element={<PaymentMethod/>}></Route>
          <Route path="/quickquoteconfirmation" element={<QuickQuoteConfirmation/>}></Route>
          <Route path="/naicscode" element={<NaicsLandingPage/>}></Route>
          <Route path="/naicshistory" element={<NaicsHistory/>}></Route>
          <Route path="/searchexisting" element={<FenrisLandingPage/>}></Route>
          <Route path="/searchnew" element={<SearchNewInsuredPage/>}></Route>
          <Route path="/valchoice" element={<ValchoiceLandingPage/>}></Route>
          <Route path="/valchoicehistory" element={<ValchoiceHistory/>}></Route>
          <Route path="/policycomparison/:index?" element={<PolicyComparison/>}></Route>
          <Route path="/policycomparisonhistory" element={<PolicyComparisonHistory/>}></Route>
          <Route path="/rabbitsign" element={<RabbitSign/>}></Route> 
          <Route path="/rabbitsignhistory" element={<RabbitSignHistory/>}></Route>
          <Route path="/mtmscan" element={<MTMScanLadingPage/>}></Route>        
          <Route path="/mtmscanhistory" element={<MTMScanHistory/>}></Route> 
          <Route path="/appetite" element={<AppetiteLandingPage/>}></Route> 
          <Route path="/appetitehistory" element={<AppetiteHistory/>}></Route> 
          <Route path="/homeowners" element={<HomeOwnersMainPage/>}></Route>
          <Route path="/homeownershistory" element={<HomeOwnersHistory/>}></Route>
          <Route path="*" element={<ErrorPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
