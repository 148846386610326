
import { useEffect, useRef } from "react";
import CommonValues from "../../common/utils";
import { useNavigate } from "react-router-dom";
export default function CoverageLimitDetails(props: any) {
  const navigate = useNavigate();
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);
  return (
    <>
      <div className="row mx-auto">
        <div className="col-12   w-100 p-0">
          <div className="history-expanded-block ">
            <div className="row mx-auto">
              <div className="col-lg-12 col-md-12 col-sm-12 details-border ">
                <table className="  history-details-font ">
                  {props.selectDocumentType == "Quote" ?
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Liability Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].LiabilityLimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].liabilityLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Deductible</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].Deductible : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].deductible : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>UM BI Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].UMBILimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].umbiLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>UIM BI Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].UIMBILimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].uimbiLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>UM PD Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].UMPDLimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].umpdLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>UIM PD Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].UIMPDLimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].uimpdLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Medical Payments Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].MedicalPaymentsLimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].medicalPaymentsLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Personal Injury Protection Limits</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].PersonalInjuryProtectionLimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].personalInjuryProtectionLimit : ""}
                          </>
                        }
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span> Autos Coverage Symbol </span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].AutoCoverageSymbol : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].autoCoverageSymbol : ""}
                          </>
                        }
                      </div>
                    </div>
                    :
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Bodily Injury & Property Damage Limit (CSL)</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].BodilyInjuryPropertyDamageLimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].bodilyInjuryPropertyDamageLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Medical Payments Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].MedicalPaymentsLimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].medicalPaymentsLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>UIM PD Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].UIMPDLimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].uimpdLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>UM BI Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].UMBILimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].umbiLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>UM PD Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].UMPDLimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].umpdLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>UIM BI Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].UIMBILimit : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].uimbiLimit : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span> Covered Autos Symbol Liability</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].CoveredAutosSymbolLiability : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].coveredAutosSymbolLiability : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Covered Autos Symbol Auto Medical Payments</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].CoveredAutosSymbolAutoMedicalPayments : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].coveredAutosSymbolAutoMedicalPayments : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span> Covered Autos Symbol P. I. P.</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].CoveredAutosSymbolPIP : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].coveredAutosSymbolPIP : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Covered Autos Symbol UM and/or UIM</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].CoveredAutosSymbolUMandorUIM : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].coveredAutosSymbolUMandorUIM : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Personal Injury Protection Limits</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].PersonalInjuryProtectionLimits : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].personalInjuryProtectionLimits : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>UIIA</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].UIIA : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].uiia : ""}
                          </>
                        }
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Deductible</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        {props.fromHistoryPage == true ?
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].Deductible : ""}
                          </> :
                          <>
                            {props.coveragelimitResponse.length > 0 ? props.coveragelimitResponse[0].deductible : ""}
                          </>
                        }
                      </div>
                    </div>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}