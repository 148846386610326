import React from 'react'
import PolicyCompareIcon from "../assets/images/policy-comparision.svg";

export default function PolicyComparisonMiddleHeader() {
  return (
    <div className="row middle-header-row ps-3">
      <div className="col-12">
        <div className="middle-header-col active ms-2">
          <img src={PolicyCompareIcon} className="" width={30}/>
          <span className="middle-header-title">Policy Comparison</span>
        </div>
      </div>{" "}
    </div>
  )
}
