import React from 'react'
import HistoryIcon from "../../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../../assets/images/NewQuote_B 1.svg";
export default function HomeOwnersSidebar(props: any) {
    const navigate = useNavigate();

    const onHomeOwnersClick = () => {
        navigate("/homeowners");
    }

    const onHomeOwnersHistoryClick = () => {
        navigate("/homeownershistory");
    }
    return (
        <div className="pt-4 pf-sidebar-block p-0">
            <>
                <span
                    className={props.pagename == "homeowner-landing-page" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
                    onClick={onHomeOwnersClick}
                >
                    <img src={NewIcon} className={props.pagename == "homeowner-landing-page" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
                    <span className="sidebar-menu-title">New Home Owner</span>{" "}
                </span>
                <span
                    className={props.pagename == "homeowner-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
                    onClick={onHomeOwnersHistoryClick}
                >
                    <img src={HistoryIcon} className={props.pagename == "homeowner-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
                    <span className="sidebar-menu-title">History</span>{" "}
                </span>
            </>
        </div>
    )
}
