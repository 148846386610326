import React, { FC, useEffect, useState, useRef } from "react";
import Header from "../common/Header";
import NcLogo from "../assets/images/momemntumpurple.webp";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import Footer from "../common/Footer";
import axios from "axios";
import CommonValues from "../common/utils";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

export default function MomentumTokenValidation() {
  const [loading, setLoading] = useState(true);
  const toast: any = useRef("");
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const trackClickForGenerateQuote = searchParams.get("trackClickForGenerateQuote");
  const widgetName = searchParams.get("widget");
  const policyid = searchParams.get("policyid");
  const insuredid = searchParams.get("insuredid");
  const embeded = searchParams.get("embeded");

  useEffect(() => {
    CommonValues.ClearAllValues();
    validateNowcertsToken(token, trackClickForGenerateQuote == null ? false : trackClickForGenerateQuote, policyid == null ? "" : policyid);
  }, []);

  const validateNowcertsToken = (token: any, trackClickForGenerateQuote: any, policyid: any) => {
    setLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_AUTHAPI}/api/account/validateNowcertsToken?token=${token}&trackClick=${trackClickForGenerateQuote}&policyid=${policyid}`,
    };

    axios(config)
      .then((response: any) => {
        if (response.data.token == "") {
          CommonValues.Logout(navigate);
        } else {
          localStorage.setItem("SocialLogin", "true");
          localStorage.setItem("igtoken", response.data.token);
          localStorage.setItem("tenantId", response.data.tenantId);
          localStorage.setItem("userName", response.data.userName);
          localStorage.setItem("RoleId", response.data.roleId);
          localStorage.setItem('NowcertsAgentId', response.data.nowCertsAgentId)

          getNcToken(response.data.userName);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while getting details!", life: 3000 });
        }
      });
  };
  const getNcToken = (ncSetupUserName: any) => {
    let token = CommonValues.GetToken();
    var data = JSON.stringify({
      NCUserName: ncSetupUserName,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/verifyMomentumCreds`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (response.data != "" && response.data != null) {
          localStorage.setItem("NCToken", response.data.access_token);
          localStorage.setItem("NCAgencyId", response.data.agencyId);
          if (widgetName == "premfi" || widgetName == "newpremfi") {
            getWizardSetupDetails();
          } else if (widgetName == "pfsettings" && embeded == "true") {
            localStorage.setItem("isSetupCompleted", "true");
            navigate("/pfsettings", { state: { embeded } });
          } else {
            getWizardSetupDetails();
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else if (error.response.status == 409) {
            toast.current.show({
              severity: "info",
              detail: "It look likes you are not registered with NowCerts, Please register first.",
              life: 3000,
            });
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while updating the NowCerts Settings", life: 3000 });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while updating the NowCerts Settings", life: 3000 });
        }
      });
  };
  const getWizardSetupDetails = () => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getwizardsetup`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          getNowCertsAgentDetails();
          if (widgetName == "premfi" || widgetName == "newpremfi") {
            insertWizardDetails(true);
          } else {
            insertWizardDetails(false);
          }
        } else {
          if (widgetName == "premfi" || widgetName == "newpremfi") {
            updateWizardSetupState();
          } else {
            if (response.data.isWizardSetupDone) {
              localStorage.setItem("isSetupCompleted", "true");
              if (widgetName == "premfi" || widgetName == "newpremfi") {
                navigate("/premiumfinance", { state: { widgetName, policyid, insuredid, embeded } });
              } else if (widgetName == "pfsettings") {
                navigate("/pfsettings", { state: { embeded } });
              } else if (widgetName == "enrichratingdata") {
                navigate("/fenrisprefill", { state: { insuredid } });
              } else if (widgetName == "paymentdetails") {
                navigate("/rabbitsign");
                //navigate("/paymentmethod");
              } else if (widgetName == 'rabbitsignsubscription'){
                navigate("/rabbitsign")
              }
              else {
                navigate("/landingpage");
              }
            } else {
              localStorage.setItem("isSetupCompleted", "false");
              navigate("/setupwizard");
            }
          }
        }
      })
      .catch((error: any) => {
        setLoading(false);
        let errorMessage = "";
        setLoading(false);

        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        }
      });
  };

  const getNowCertsAgentDetails = () => {
    // clearValues();
    var token = CommonValues.GetToken();
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    let nowCertsAgencyId = localStorage.getItem("NCAgencyId") ? localStorage.getItem("NCAgencyId") : "";
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/agencyinfo?nowCertsToken=${nowCertsToken}&nowCertsAgencyId=${nowCertsAgencyId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        let agencyData = {
          agencyName: response.data.agencyName,
          agencyAddress1: response.data.addressLine1,
          agencyAddress2: response.data.addressLine2,
          agencyCity: response.data.city,
          agencyState: response.data.state,
          agencyZip: response.data.zipCode,
          agencyCountry: "USA",
          agencyPhone: response.data.phone,

        };
        onSaveAmsAgencySetting(agencyData);
      })
      .catch((error: any) => {
        setLoading(false);
        // clearValues();
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status == 409) {
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting Nowcerts agent details", life: 3000 });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while getting Nowcerts agent details", life: 3000 });
        }
      });
  };
  const onSaveAmsAgencySetting = (agencyData: any) => {
    // if (checkValidation()) {

    const token = CommonValues.GetToken();
    var data = {
      CompanyId: agencyData.CompanyId,
      AgencyName: agencyData.agencyName,
      Country: agencyData.agencyCountry,
      Address1: agencyData.agencyAddress1,
      Address2: agencyData.agencyAddress2,
      State: agencyData.agencyState,
      Zip: agencyData.agencyZip,
      City: agencyData.agencyCity,
      Phone: agencyData.agencyPhone,

    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => { })
      .catch((error: any) => {
        setLoading(false);
        // setInvalidCredMessage("");

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 404) {
            toast.current.show({ severity: "error", detail: "Invalid ams360 credentials!", life: 3000 });
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while saving the AMS360 settings", life: 3000 });
          }
        }
      });
    // }
  };
  const updateWizardSetupState = () => {
    var token = CommonValues.GetToken();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/updatewizardsetup?IsWizardSetupDone=${true}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.data.success == true) {
          localStorage.setItem("isSetupCompleted", "true");
          localStorage.removeItem("currentPage");
          if (widgetName == "premfi" || widgetName == "newpremfi") {
            navigate("/premiumfinance", { state: { widgetName, policyid, insuredid } });
          } else {
            navigate("/landingpage");
          }
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while updating wizard setup state";
          }
        } else {
          errorMessage = "Unknown error while  updating wizard setup state";
        }
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
      });
  };
  // const getMomentumToken = () => {
  //   const token = CommonValues.GetToken();
  //   const config = {
  //     method: "get",
  //     url: `${process.env.REACT_APP_UserService_Url}/api/user/getmomentumtoken`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios(config)
  //     .then((response: any) => {
  //       if (response.data != "" || response.data != null) {
  //         localStorage.setItem("NCToken", response.data.access_token);
  //         localStorage.setItem("NCAgencyId", response.data.agencyId);
  //         if (widgetName == "premfi") {
  //           localStorage.setItem("isSetupCompleted", "true");
  //           navigate("/premiumfinance", { state: { widgetName, policyid, insuredid } });
  //         }
  //         if (widgetName == "pfsettings" && embeded == "true") {
  //           localStorage.setItem("isSetupCompleted", "true");
  //           navigate("/pfsettings", { state: { embeded } });
  //         } else {
  //           getWizardSetupDetails();
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       let errorMessage = "";
  //       if (error.response != null) {
  //         if (error.response.status === 401) {
  //           navigate("/");
  //           CommonValues.Logout(navigate);
  //         } else {
  //           errorMessage = "Unknown error while loading the NowCerts Setting details";
  //           toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
  //         }
  //       } else {
  //         errorMessage = "Unknown error while loading the NowCerts Setting details";
  //         toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
  //       }
  //     });
  // };
  const insertWizardDetails = (val: any) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/insertwizardsetup?IsWizardSetupDone=${val}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoading(false);
        if (response.data.isWizardSetupDone) {
          if (widgetName == "premfi" || widgetName == "newpremfi") {
            navigate("/premiumfinance", { state: { widgetName, policyid, insuredid } });
          } else if (widgetName == "enrichratingdata") {
            navigate("/fenrisprefill", { state: { insuredid } });
          } else if (widgetName == "pfsettings") {
            navigate("/pfsettings", { state: { embeded } });
          } else {
            navigate("/landingpage");
          }
          localStorage.setItem("isSetupCompleted", "true");
        } else {
          navigate("/setupwizard");
          localStorage.setItem("isSetupCompleted", "false");
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        setLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        }
      });
  };
  return (
    <div className="momentum-sso-page">
      <Toast ref={toast} />
      {embeded == "true" ? null : <Header pagename="momentum-sso-page" />}
      
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
        <div className="login-box ">
          <div className="row mx-auto d-flex align-items-center">
            <div className="col-12 text-center  nowcerts-loader">
              {loading ? (
                <>
                  <div className=" authenticating-block text-center">
                    <img src={NcLogo} height="60px" />
                    <div className="mt-3 mb-3">
                      <span className="">Authenticating</span>
                    </div>
                    <div className="d-flex justify-content-center nowcerts-sso-loader">
                      <AtkLoader />
                    </div>
                  </div>
                </>
              ) : (
                <div className=" d-flex align-items-center justify-content-center" style={{ minHeight: "100px" }}>
                  <span className="not-authorize-text">You are not authorized</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* {embeded == "true" ? null : <Footer />} */}
    </div>
  );
}
