import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from '../../common/Footer'
import { Toast } from 'primereact/toast'
import Header from '../../common/Header'
import HomeOwnerMiddleHeader from "./HomeOwnerMiddleHeader";
import HomeOwnersSidebar from "./HomeOwnersSidebar";

export default function HomeOwnersHistory() {
    const navigate = useNavigate();
    const toast: any = useRef("");
    useEffect(() => {
        window.scroll(0, 0);
      }, []);
    return (
        <>
            <div className="homeowner-main-page">
                <Toast ref={toast} />
                <Header pagename="homeowner-history" />
                <div className="content-wrapper">
                    <div className="row  wrapper-main-row mx-auto wrapper-below-div">
                        <div className="col-12">
                            <HomeOwnerMiddleHeader />
                        </div>
                        <div className="row main-content-row mx-auto ">
                            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                                <HomeOwnersSidebar pagename="homeowner-history" />
                            </div>

                            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                                <div className="row   d-flex  align-items-end text-12 position-relative">
                                    {/* <HomeOwnersHistory /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}
