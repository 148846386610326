import React, { useEffect, useState } from "react";
import AtkCheckbox from "../AtlaskitControls/AtkCheckbox";
import PfLogo from "../assets/images/PF_Logo.svg";
export default function WidgetList(props: any) {
  const [selectPremiumFinance, setSelectPremiumFinance] = useState(true);
  useEffect(() => {
    props.onWidgetSelectedUpdate(selectPremiumFinance);
  }, []);
  const onPremiumFinanceChange = () => {
    props.onWidgetSelectedUpdate(!selectPremiumFinance);
    setSelectPremiumFinance(!selectPremiumFinance);
  };

  return (
    <div className="credentials-div rounded mb-2">
      <div className="row  mx-auto my-2">
        {/* <div className="col-12 mb-2">
          <a className="select-widget-link">Select All</a>
          <a className="select-widget-link">Unselect All</a>
        </div> */}
        <div className="col-lg-3 col-md-3 col-sm-5 col-11  m-2">
          <div className="row widget-block">
            <div className="col-2">
              <AtkCheckbox label="" name={selectPremiumFinance} isChecked={selectPremiumFinance} onChange={onPremiumFinanceChange} />
            </div>
            <div className="col-2">
              <img src={PfLogo} height={30} width={30} className="d-block" />
            </div>
            <div className="col-8">
              <span className="text-dark">Premium Finance</span>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-3 col-md-3 col-sm-5 col-11 m-2">
          <div className="row widget-block">
            <div className="col-2">
              <AtkCheckbox label="" name={selectPremiumFinance} isChecked={selectPremiumFinance} onChange={onPremiumFinanceChange} />
            </div>
            <div className="col-10">
              <img src={PfLogo} height={30} width={30} className="d-block"/>
              <span>CL Rater</span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
