import React from "react";
import Footer from "../common/Footer";
import NaicsSidebar from "./NaicsSidebar";
import Header from "../common/Header";
import NaicsMiddleHeader from "./NaicsMiddleHeader";
import { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import { Dropdown } from "primereact/dropdown";
import AtkButtonIconBefore from "../AtlaskitControls/AtkButtonIconBefore";
import AtkIcon from "../AtlaskitControls/AtkIcon";
import Magnifier from "../assets/images/MagnifierIcon.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { InputSwitch } from "primereact/inputswitch";
import CommonValues from "../common/utils";
import { useCallback } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import _ from "lodash";
import AtkButton from "../AtlaskitControls/AtkButton";

export default function NaicsLandingPage() {
  const toast: any = useRef("");
  const [naicsDescription, setNaicsDescription] = useState("");
  const [clientName, setClientName] = useState("");
  const [naicsCodeLoading, setNaicsCodeLoading] = useState(false);
  const [updateInsuredDetailsLoading, setUpdateInsuredDetailsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [naicsCodeForLoading, setNaicsCodeForLoading] = useState("");
  const navigate = useNavigate();
  const [naicsCodeList, setNaicsCodeList] = useState<any>([]);
  const [insuredDatabaseId, setInsuredDatabaseId] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const debouncedFetchResults = useCallback(
    _.debounce((searchQuery: any) => {
      if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
        getNowCertsClients(searchQuery);
      }
    }, 500),
    []
  );
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (search.replaceAll(" ", "") == "") {
      return;
    }
    setSearchClientLoading(true);
    if (search) {
      debouncedFetchResults(search);
    }
    return () => {
      debouncedFetchResults.cancel();
    };
  }, [search, debouncedFetchResults]);

  useEffect(() => {
    getCardDetails();
  }, []);

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  const onNaicsDescriptionChange = (e: any) => {
    setNaicsDescription(e.target.value);
  };
  const onClientSearchChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setSearch(value);
  };
  const onClientSelect = (client: any) => {
    setSearch("");
    setClientName(client.name);
    setInsuredDatabaseId(client.databaseId);
  };
  const onSelectInputSwitch = (rowData: any) => {
    setNaicsCodeForLoading(rowData.naicsCode);
    setNaicsCodeList((prevList: any) =>
      prevList.map((item: any) => {
        if (item.naicsCode === rowData.naicsCode) {
          updateInsuredNaicsCodeDetails(rowData, item.naicsCode);
          return { ...item };
        }
        return item;
      })
    );
  };
  const actionTemplate = (rowData: any) => {
    return updateInsuredDetailsLoading && rowData.naicsCode == naicsCodeForLoading ? (
      <AtkLoader />
    ) : rowData.updatedInAms ? (
      <span className="text-info">Updated In AMS</span>
    ) : (
      <AtkButton label="Update in NowCerts" onClick={() => onSelectInputSwitch(rowData)} className="pf-primary-btn w-auto show-cursor" />
    );
  };
  const checkValidation = () => {
    let returnValue = true;
    setDescriptionError("");

    if (naicsDescription == null || naicsDescription == "") {
      setDescriptionError("Please enter NAICS description");
      returnValue = false;
    }
    const re = /[|*:\"<>[\]{}\\()`';@&$!^]/;
    if (naicsDescription != null && naicsDescription != "" && re.test(naicsDescription)) {
      setDescriptionError("Please enter a valid NAICS description without special characters.");
      returnValue = false;
    }
    return returnValue;
  };
  const onSearchClick = () => {
    if (checkValidation()) {
      var token = CommonValues.GetToken();
      setNaicsCodeLoading(true);

      var data = {
        SearchText: naicsDescription,
        InsuredName: clientName,
      };
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_DataFabric_Url}/api/naics/ai/naics`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          if (response.data.success) {
            const updatedCodeInfo = response.data.codeInfo.map((item: any, index: number) => ({
              ...item,
              index: index,
              transactionId: response.data.transactionId,
              updatedInAms: false,
            }));
            setNaicsCodeLoading(false);
            setNaicsCodeList(updatedCodeInfo);
            getCardDetails();
          } else if (!response.data.success && response.data.errorMessage === "User does not have enough credits for NAICS Classification.") {
            setNaicsCodeLoading(false);
            toast.current.show({
              severity: "info",
              detail: response.data.errorMessage,
              life: 3000,
            });
          } else if (!response.data.success) {
            setNaicsCodeLoading(false);
            toast.current.show({
              severity: "info",
              detail: response.data.errorMessage,
              life: 3000,
            });
          }
        })
        .catch((error: any) => {
          setNaicsCodeLoading(false);

          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else {
              toast.current.show({ severity: "error", detail: "Unknown error while gettings NAICS Code", life: 3000 });
            }
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while gettings NAICS Code", life: 3000 });
          }
        });
    }
  };

  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while loading the insureds data.Please try again later.",
              life: 3000,
            });
          }
        }
        setSearchClientLoading(false);
      });
  };
  const updateInsuredNaicsCodeDetails = (rowData: any, naicsCode: any) => {
    setUpdateInsuredDetailsLoading(true);
    var data = {
      naicsCode: naicsCode,
      insuredDatabaseId: insuredDatabaseId,
      transactionId: rowData.transactionId,
      naicsIndex: rowData.index,
      updatedInAMS: true,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/naics/updateinsureddetails?ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setUpdateInsuredDetailsLoading(false);
        const updatedCodeInfo = naicsCodeList.map((codeItem: any, index: number) => ({
          ...codeItem,
          updatedInAms: codeItem.naicsCode === naicsCode ? true : false,
        }));
        setNaicsCodeList(updatedCodeInfo);

        toast.current.show({ severity: "success", detail: "Naics code updated successfully", life: 3000 });
      })
      .catch((error) => {
        setUpdateInsuredDetailsLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while updating insured details.",
              life: 3000,
            });
          }
        }
        setSearchClientLoading(false);
      });
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      onSearchClick();
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="naics-landing-main-page">
        <Header pagename="naics-landing-page" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <NaicsMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <NaicsSidebar pagename="naics-landing-page" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row history-search-row d-flex  align-items-center text-12 position-relative">
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 client-search-input-col position-relative p-0">
                    <div className="search-bar ">
                      <AtkTextField
                        type="text"
                        id="naics-client-name"
                        className={`search-area ${clientName.length > 0 ? "insured-placeholder" : " "}`}
                        name="clientName"
                        onChange={(e) => {
                          onClientSearchChange(e.target.value);
                        }}
                        value={search}
                        placeholder={clientName != null && clientName != "" ? clientName : "Enter client name"}
                      />
                    </div>
                    {search.replaceAll(" ", "").length > 0 && (
                      <div id="client-search" className=" position-absolute  ">
                        {searchClientLoading ? (
                          <div className="d-flex justify-content-center my-2 client-loader-div">
                            <AtkLoader />
                          </div>
                        ) : searchClientList.length == 0 ? (
                          <div style={{ padding: "5px", background: "white" }}>
                            <span style={{ color: "#ddd", marginLeft: "10px" }}>No results found</span>
                          </div>
                        ) : (
                          searchClientList.map((item: any) => (
                            <div
                              className="show-cursor w-100"
                              onClick={() => {
                                onClientSelect(item);
                              }}
                            >
                              <a href="#" className="carrier-link">
                                {item.name}
                              </a>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                  <div className={`col-lg-3 col-md-3 col-sm-12  ${descriptionError.length > 0 ? "validation-div" : ""}`}>
                    <AtkTextField
                      type="text"
                      id="text"
                      name="clientName"
                      onChange={onNaicsDescriptionChange}
                      value={naicsDescription}
                      placeholder="Enter NAICS description"
                      className="input-field"
                      onKeyDown={onEnterClick}
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-12  history-searchbtn-div">
                    {naicsCodeLoading ? (
                      <div className="d-flex  align-items-center  fix-width-support ">
                        <AtkLoader></AtkLoader>
                      </div>
                    ) : (
                      <AtkButtonIconBefore
                        label="Search"
                        iconBefore={<AtkIcon icon={Magnifier} />}
                        className="pf-primary-btn"
                        onClick={onSearchClick}
                      />
                    )}
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 naics-show-balance">
                    <div className="row w-100 d-flex align-items-center justify-content-end">
                      <div className="col-9 text-center balance-span">
                        <label>Current Balance</label>
                        {currentBalanceLoading ? (
                          <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                            <AtkLoader />
                          </span>
                        ) : (
                          <span className=" d-block w-auto ">${currentBalance}</span>
                        )}
                        <small className="d-block w-auto text-danger mt-0">25 cent will be charged per usage</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 "></div>
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 ">
                    <span className="text-12 text-danger">{descriptionError}</span>
                  </div>
                </div>
                <div className="row mt-3">
                  {/* {naicsCodeLoading ? (
                    <div className="d-flex justify-content-center align-items-center mt-2 fix-width-support ">
                      <AtkLoader></AtkLoader>
                    </div>
                  ) : ( */}
                  <div className="col-12  history-table p-3 rounded-bottom">
                    <DataTable
                      value={naicsCodeList}
                      paginator
                      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                      rows={8}
                      selectionMode="single"
                    >
                      <Column header="NAICS Code" field="naicsCode" sortable bodyClassName="hide-cursor"></Column>
                      <Column header="Description" field="description" sortable bodyClassName="hide-cursor"></Column>
                      {clientName == null || clientName == "" ? null : (
                        <Column header="Action" body={actionTemplate} bodyClassName="hide-cursor"></Column>
                      )}
                    </DataTable>
                  </div>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
