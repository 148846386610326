import PfLogo from "../assets/images/PF_Logo.svg";

export default function MiddleHeader() {

  return (
    <div className="row middle-header-row ps-3">
      <div className="col-12">
        <div className="middle-header-col active ms-2">
          <img src={PfLogo} className="middle-header-logo" />
          <span className="middle-header-title">PremFi</span>
        </div>
      </div>{" "}
    
    </div>
  );
}
