import React from 'react'
import MTMLogo from "../../assets/images/MTMLogo.png";
import { useEffect, useRef } from "react";
import CommonValues from "../../common/utils";
import { useNavigate } from "react-router-dom";

export default function MTMScanMiddleHeader(props:any) {
  const toast: any = useRef()
  const navigate = useNavigate();
  useEffect(() => {
      const token = CommonValues.GetToken();
      if (token == "" || token == null) {
          CommonValues.Logout(navigate);
      }
  }, []);
  return (
    <div className="row middle-header-row ps-3">
      <div className="col-12">
        <div className="coi-scan-middle-header-col active ms-2">
          <img src={MTMLogo} className="landing-page-img" />
          <span className="middle-header-title">MTM Scan</span>
        </div>
      </div>{" "}
    </div>
  )
} 