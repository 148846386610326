import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../common/Header";
import BillingSidebar from "./BillingSidebar";
import Footer from "../common/Footer";
import { Elements } from "@stripe/react-stripe-js";
import InjectedStripePaymentMethod from "./StripePaymentMethod";
import { loadStripe } from "@stripe/stripe-js";
import BillingMiddleHeader from "./BillingMiddleHeader";

export default function PaymentMethod() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Toast ref={toast} />
      <div className="buy-credits-main-page">
        <Header pagename="billing-payment-method" />
        {/*  */}
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <BillingMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <BillingSidebar pagename="billing-payment-method" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row d-flex  align-items-end text-12 position-relative">
                <div className="col-12">
                      <Elements stripe={stripePromise}>
                        <InjectedStripePaymentMethod />
                      </Elements>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
