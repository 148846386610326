import React from 'react'
import HomeIcon from "../../assets/images/HomeOwnerLogo_TH.svg";
export default function HomeOwnerMiddleHeader() {
    return (
        <div className="row middle-header-row">
            <div className="col-12">
                <div className="middle-header-col active ms-2">
                    <img src={HomeIcon} className="" width={30} />
                    <span className="middle-header-title">Home Owners</span>
                </div>
            </div>{" "}
        </div>
    )
}
