import React from "react";
import moment from "moment";

export default function Footer() {
  let year = moment().year();

  return (
    <footer className=" text-14  text-dark pf-footer">
      <div className="row p-2 footer-copyright-div mx-auto">
       <div className="col-lg-12 col-md-12 col-sm-12 col-12 copyright-block">Momentumtoolbox {year} © All Rights Reserved</div>
      </div>
    </footer>
  );
}
