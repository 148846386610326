import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../common/Footer";
import { Toast } from "primereact/toast";
import Header from "../../common/Header";
import HomeOwnerMiddleHeader from "./HomeOwnerMiddleHeader";
import HomeOwnersSidebar from "./HomeOwnersSidebar";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import CommonValues from "../../common/utils";
import axios from "axios";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import Toggle from "@atlaskit/toggle";

export default function HomeOwnersMainPage() {
  const navigate = useNavigate();
  const toast: any = useRef("");

  const [search, setSearch] = useState("");
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientNameError, setClientNameError] = useState("");
  const [clientActionError, setClientActionError] = useState("");
  const [selectedPropertyError, setSelectedPropertyError] = useState("");
  const [insuredDatabaseId, setInsuredDataBaseId] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalanceUpdateFlag, setCurrentBalanceUpdateFlag] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addressLine1, setAddressLine1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isExistingClientSelected, setIsExistingClientSelected] = useState(false);
  const [selectedProspect, setSelectedProspect] = useState("createNew");
  const [createNewClientSelected, setCreateNewClientSelected] = useState(false);
  const [propertylist, setPropertylist] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [propertyListLoading, setPropertyListLoading] = useState(false);
  const [propertyTypesList, setPropertyTypesList] = useState([]);
  const [title, setTitle] = useState("");
  const [propType, setPropType] = useState("");
  const [ownerOccupied, setOwnerOccupied] = useState("");
  const [address, setAddress] = useState("");
  const [cityData, setCityData] = useState("");
  const [country, setCountry] = useState("");
  const [yearBuilt, setYearBuilt] = useState("");
  const [roofing, setRoofing] = useState("");
  const [province, setProvince] = useState("");
  const [numUnit, setNumUnit] = useState("");
  const [lotSize, setLotSize] = useState("");
  const [exteriorConstruction, setExteriorConstruction] = useState("");
  const [estimatedPrices, setEstimatedPrices] = useState("");
  const [mlsNumber, setMlsNumber] = useState("");
  const [categories, setCategories] = useState("");
  const [showDetails, setShowDetails] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    getCardDetails();
  }, [currentBalanceUpdateFlag]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (search == "") {
      return;
    }
    setSearchClientLoading(true);
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(search);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [search]);
  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance("" + response.data.currentBalance / 100);
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while loading the insureds data.Please try again later.",
              life: 3000,
            });
          }
        }
        // setErrorMessage(errorMessage);
        setSearchClientLoading(false);
      });
  };
  const onClientSearchChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setSearch(value);
    setClientName(value);
  };
  const onClientSelect = (client: any) => {
    setSearch("");
    setClientName(client.name);
    getNowCertsClientsById(client.databaseId);
  };
  const onCategoriesChange = (e: any) => {
    setCategories(e.target.value);
  };
  const onMlsNumberChange = (e: any) => {
    setMlsNumber(e.target.value);
  };
  const onEstimatedPricesChange = (e: any) => {
    setEstimatedPrices(e.target.value);
  };
  const onExteriorConstructionChange = (e: any) => {
    setExteriorConstruction(e.target.value);
  };
  const onLotSizeValueChange = (e: any) => {
    setLotSize(e.target.value);
  };
  const onNumUnitChange = (e: any) => {
    setNumUnit(e.target.value);
  };
  const onProvinceChange = (e: any) => {
    setProvince(e.target.value);
  };
  const onRoofingChange = (e: any) => {
    setRoofing(e.target.value);
  };
  const onYearBuiltChange = (e: any) => {
    setYearBuilt(e.target.value);
  };
  const onCountryChange = (e: any) => {
    setCountry(e.target.value);
  };
  const onCityChange = (e: any) => {
    setCity(e.target.value);
  };
  const onAddressChange = (e: any) => {
    setAddress(e.target.value);
  };
  const onOwnerOccupiedChange = (e: any) => {
    setOwnerOccupied(e.target.value);
  };
  const onPropertyTypeChange = (e: any) => {
    setPropertyTypesList(e.target.value);
  };
  const onTitleChange = (e: any) => {
    setTitle(e.target.value);
  };
  const getNowCertsClientsById = (clientId: any) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var data = {
      insuredDatabaseId: insuredDatabaseId,
    };
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${clientId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        let insuredInfo = response.data;
        if (insuredInfo != null && insuredInfo.length > 0) {
          setCity(insuredInfo[0].city);
          setAddressLine1(insuredInfo[0].addressLine1);
          setPhoneNumber(insuredInfo[0].phone);
          setState(insuredInfo[0].stateNameOrAbbreviation);
          setZipCode(insuredInfo[0].zipCode);

          getInsuredProperties(insuredInfo[0].databaseId);
        }
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Error while getting insured data.",
              life: 3000,
            });
          }
        }
      });
  };
  const checkValidation = () => {
    let formIsValid = true;
    if (createNewClientSelected == false && isExistingClientSelected == false) {
      setClientActionError("Please select client action");
      formIsValid = false;
    }
    if (isExistingClientSelected == true && (clientName == "" || clientName == null)) {
      setClientNameError("Please select client name");
      formIsValid = false;
    }
    if (selectedProperty == "" || selectedProperty == null) {
      setSelectedPropertyError("Please select property");
      formIsValid = false;
    }
    return formIsValid;
  };
  const onGetHomeOwnersDataClicked = () => {
    if (checkValidation()) {
      setShowDetails(false);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      // var data = JSON.stringify({
      //     "query": `country:\"US\" AND address:\"4912 S Lois Ave\" AND city:\"Tampa\" AND province:FL AND postalCode:33611`,
      //     "num_records": 2
      // });

      // var config = {
      //     method: 'post',
      //     url: 'https://api.datafiniti.co/v4/properties/search',
      //     headers: {
      //         'Content-Type': 'application/json',
      //         'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJyNjNhYnJobXY4enZyNWxqbHB0OG8zcTMzYmI0c3Z1ayIsImlzcyI6ImRhdGFmaW5pdGkuY28ifQ.NIW4uswB_Gw4F53j8YyvMOQCJZtdlK9PHRjgwX0kQVXu9IgEjQYH5WvPdEqj_cFwLhZdZXuz-3zPKGeyAfX6LbVeOtqp8jiVHd1H_Bk5VmEUmDhchfmirx1C-5Glr_7B-yKEJA1J55yoz9pbIOHp4ATPVc_0z3i2xEa_3ggWGTPTNZhu3kkQwBlXUGLGr6Shzs8OEZg2VgABmCrTW4yBniczQYcbH1warrKy8n060Ktoe8E0oV7CBsoaMsxI4Jk5J2bZhA57z39vsQLkMpO1ebC8dn3Qi_K8URK_NU6ArG59XzRdncAR_eRV-eIUjwEmW2IZtWb_-vkstHja2TTEOZOBcL2yHEntSj97BqqonDWG9vW_Mam18eaI8d-OVz9x-6_O2Wss7Vi6yA-MnXeEwUzrd8KpTgjBqNniKgbJ84b7eWBftks9DKRuCvtJnhFD1s8r4cmeNasauiWEYapwh9NouFf8iicPY3L3HOhJ2WKKXUGlzMH2qPhZ8cvedn8h53x3Ktmk2NVnPHSyQn-17OYh5v6LfwWHxvNiCYSqg3gYbtwDHBXd9dOFpDbKLaagbaKCe30EMcO3EpUKs67mJ6kVcCFRxTgTxXmBiZl0sACW-vfLs2ReROiIoeB6WmNxyBbcQbiwBiNwYz7GnmvJSTj9I4reayXgnZpCeoVhXA4'
      //     },
      //     data: data
      // };

      // axios(config)
      //     .then((response: any) => {
      //         console.log(JSON.stringify(response.data));
      //     })
      //     .catch((error: any) => {
      //         console.log(error);
      //     });
    }
  };
  const onSubmitClick = () => {
    setSubmitLoading(true);
    setTimeout(() => {
      setShowDetails(true);
      setSubmitLoading(false);
      toast.current.show({
        severity: "success",
        detail: "Property data updated successfully.",
        life: 3000,
      });
    }, 3000);
  };
  const handleClientChange = (event: any, value: any) => {
    if (value === "useExisting" && event.target.checked == true) {
      setIsExistingClientSelected(true);
      setCreateNewClientSelected(false);
    } else if (value === "createNew" && event.target.checked == true) {
      setCreateNewClientSelected(true);
      setIsExistingClientSelected(false);
    }

    setSelectedProspect(value);
    if (value == "createNew") {
      setClientName("");
      setClientId("");
      setClientNameError("");
    }
  };
  const getInsuredProperties = (insuredId: any) => {
    setPropertyListLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";

    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/propertylist?ncToken=${nowCertsToken}&insuredId=${insuredId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((response: any) => {
        let list: any = [];
        response.data.forEach((item: any) => {
          list.push({
            id: item.id,
            property: item.propertyUse + " - " + item.addressLine1 + ", " + item.city + ", " + item.zip,
          });
        });
        setPropertylist(list);
        setPropertyListLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setPropertyListLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Error while getting insured property data.",
              life: 3000,
            });
          }
        }
      });
  };
  const onPropertySelected = (e: any) => {
    setSelectedProperty(e.value);
  };
  return (
    <>
      <div className="homeowner-main-page">
        <Toast ref={toast} />
        <Header pagename="homeowner-landing-page" />
        <div className="content-wrapper">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <HomeOwnerMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto ">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <HomeOwnersSidebar pagename="homeowner-landing-page" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  p-0">
                    <div className="row scan-search-row mx-auto ">
                      <div className="col-9">
                        <div className="row">
                          <div className={`col-4 ${clientActionError.length > 0 ? "validation-div" : ""}`}>
                            <div className="row   ">
                              <p className="text-14 fw-bold">Client</p>
                              <div className="col-12 d-flex">
                                <div className="p-field-checkbox d-flex align-items-center me-2">
                                  <label htmlFor="createNew">Create New</label>
                                  <Toggle
                                    id="createNew"
                                    isChecked={createNewClientSelected}
                                    onChange={(e) => handleClientChange(e, "createNew")}
                                  />{" "}
                                </div>
                                <div className="p-field-checkbox  d-flex align-items-center">
                                  <label htmlFor="useExisting">Use Existing</label>
                                  <Toggle
                                    id="useExisting"
                                    isChecked={isExistingClientSelected}
                                    onChange={(e) => handleClientChange(e, "useExisting")}
                                  />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={`col-4 position-relative  p-0 ${clientNameError.length > 0 ? "validation-div" : ""}`}>
                            <p className="text-14 fw-bold mb-1 ">Select Client </p>

                            <div className={`search-bar flex-column `}>
                              {/* ${clientNameError.length > 0 ? " validation-div " : " "}`}> */}
                              <AtkTextField
                                type="text"
                                id="client-name"
                                className={` ${clientName.length > 0 ? "clientname-placeholder" : ""} search-area w-100 input-field `}
                                name="search"
                                onChange={(e) => {
                                  onClientSearchChange(e.target.value);
                                }}
                                value={search}
                                placeholder={clientName.length > 0 ? clientName : "Enter client name"}
                                // isDisabled={selectedProspect !== "useExisting"}
                              />
                            </div>
                            {search.replaceAll(" ", "").length > 0 && (
                              <div id="client-search" className=" position-absolute  ">
                                {searchClientLoading ? (
                                  <div className="d-flex justify-content-center my-2 client-loader-div">
                                    <AtkLoader />
                                  </div>
                                ) : searchClientList.length == 0 ? (
                                  <div style={{ padding: "5px", background: "white" }}>
                                    <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                                  </div>
                                ) : (
                                  searchClientList.map((item: any) => (
                                    <div
                                      style={{ cursor: "pointer", width: "100%" }}
                                      onClick={() => {
                                        onClientSelect(item);
                                      }}
                                    >
                                      <a
                                        href="#"
                                        style={{
                                          color: "black",
                                          width: "100%",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {item.name}
                                      </a>
                                    </div>
                                  ))
                                )}
                              </div>
                            )}
                            {/* <span className="text-danger text-12">{clientNameError}</span> */}
                          </div>
                          <div className={`col-4 ${selectedPropertyError.length > 0 ? "validation-div" : ""}`}>
                            <div className="row text-14 ">
                              <p className="text-14 fw-bold mb-1">Select Property</p>
                              <div className="col-12">
                                <div className="d-flex align-items-center">
                                  {propertyListLoading ? (
                                    <div className="d-flex justify-content-center my-2">
                                      <AtkLoader />
                                    </div>
                                  ) : (
                                    <Dropdown
                                      className="finance-company-dropdown input-field"
                                      placeholder="Select property"
                                      options={propertylist}
                                      optionLabel="property"
                                      onChange={onPropertySelected}
                                      value={selectedProperty}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-3 d-flex align-items-end">
                        <div className="row ">
                          <div className="col-12 mb-1">
                            <AtkButtonIconBefore label="Get Home Owners Data" className="pf-secondary-btn" onClick={onGetHomeOwnersDataClicked} />
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <span className="text-danger text-12 ">{clientActionError}</span>
                      </div>

                      <div className="col-3 p-0">
                        <span className="text-danger text-12 ">{clientNameError}</span>
                      </div>
                      <div className="col-3">
                        <span className="text-danger text-12 ">{selectedPropertyError}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {showDetails ? null : (
                  <>
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center mt-5">
                        <AtkLoader />
                      </div>
                    ) : (
                      <div className="row mt-3 mx-auto text-12">
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Title to Description</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={title}
                            onChange={onTitleChange}
                            placeholder="Enter name"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Property Type</label>

                          <Dropdown
                            className="finance-company-dropdown input-field"
                            type="text"
                            id="text"
                            value={propType}
                            options={propertyTypesList}
                            onChange={onPropertyTypeChange}
                            placeholder="Select Property Type"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Owner Occupied</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={ownerOccupied}
                            onChange={onOwnerOccupiedChange}
                            placeholder="Enter name"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Address</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={address}
                            onChange={onAddressChange}
                            placeholder="Enter name"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>City</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={cityData}
                            onChange={onCityChange}
                            placeholder="Enter name"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Country</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={country}
                            onChange={onCountryChange}
                            placeholder="Enter name"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Year Built</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={yearBuilt}
                            onChange={onYearBuiltChange}
                            placeholder="Enter name"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Roofing</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={roofing}
                            onChange={onRoofingChange}
                            placeholder="Enter name"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Province</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={province}
                            onChange={onProvinceChange}
                            placeholder="Enter name"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Num Unit</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={numUnit}
                            onChange={onNumUnitChange}
                            placeholder="Enter Num Unit"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Lot Size Value</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={lotSize}
                            onChange={onLotSizeValueChange}
                            placeholder="Enter Lot Size Value"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Exterior Construction</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={exteriorConstruction}
                            onChange={onExteriorConstructionChange}
                            placeholder="Enter Exterior Construction"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Estimated Prices </label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={estimatedPrices}
                            onChange={onEstimatedPricesChange}
                            placeholder="Enter Estimated Prices"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>MLS Number</label>

                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={mlsNumber}
                            onChange={onMlsNumberChange}
                            placeholder="Enter MLS Number"
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                          <label>Categories</label>

                          <Dropdown
                            className="finance-company-dropdown input-field"
                            type="text"
                            id="text"
                            value={categories}
                            options={categoriesList}
                            onChange={onCategoriesChange}
                            placeholder="Select Categories"
                          />
                        </div>
                        <div className="col-12 text-end ">
                          {submitLoading ? (
                            <AtkLoader />
                          ) : (
                            <AtkButtonIconBefore label="Submit" className="pf-secondary-btn" onClick={onSubmitClick} />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
